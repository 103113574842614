import React from 'react'
import Topbar from '../components/Topbar';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Centralbody from '../components/Centralbody';

const Home = (props) => {

  const {links,url,nomeSing,nomePlur,apiModel,enableSave,enableDelete,useLogUrl} = props

  return (
    <>
    <Topbar url={url} nomePlur={nomePlur} />
    <section className='centralbody-container d-flex'>
      <Menu links={links} />
      <Centralbody url={url} nomeSing={nomeSing} nomePlur={nomePlur} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} useLogUrl={useLogUrl} />
    </section>
    <Footer />
    </>
  )
}

export default Home
