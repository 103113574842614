import { useParams } from 'react-router-dom'
import Editform from '../components/Editform'
import Newform from '../components/Newform'

const Edit = (props) => {

  const {url,nomeSing,apiModel,enableSave,enableDelete,useLogUrl} = props

  const {id} = useParams()

  return (
    <>
      {id !== 'new' && <><Editform url={url} nomeSing={nomeSing} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} useLogUrl={useLogUrl} /></>}
      {id === 'new' && <><Newform url={url} nomeSing={nomeSing} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} useLogUrl={useLogUrl} /></>}
    </>
  )
}

export default Edit