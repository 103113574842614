import React from 'react'
import Index from '../views/Index'
import Edit from '../views/Edit'
import Login from '../views/Login'
import { useSelector, useDispatch } from 'react-redux'
import { setShow } from '../app/store'
import { useParams } from 'react-router-dom'

const Centralbody = (props) => {

    const user = useSelector((state)=> state.user)
    const menu = useSelector((state)=> state.menu)
    const dispatch = useDispatch()
    const {id} = useParams()
    const {url,nomeSing,nomePlur,apiModel,enableSave,enableDelete,useLogUrl} = props

     /* New model creation logic managed in Edit */
    return (
        <div className={'hero'+(menu.show?' menu-open':'')} onClick={()=>{if(menu.show)dispatch(setShow(false))}}>

        <div className=''>
          {!user.logged_in && <Login />}
          {user.logged_in && <Index nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} useLogUrl={useLogUrl} />}
          {user.logged_in && id && <Edit nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} useLogUrl={useLogUrl} />}
        </div>

      </div>
    )
}

export default Centralbody